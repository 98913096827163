import boyngirlsclubpic from './boyngirlsclubpic.jpg';
import boyngirlsclubpic3 from './boyngirlsclubpic3.jpg';
import boyngirlsclubpic4 from './boyngirlsclubpic4.jpg';
import boysngirlsclub2 from './boysngirlsclub2.jpg';
import karate1 from './karate1.jpg';
import karate2 from './karate2.jpg';
import karate3 from './karate3.jpg';
import karate4 from './karate4.jpg';
import kids from './kids.jpg';
import kids2 from './kids2.jpg';
import kids3 from './kids3.jpg';
import kids4 from './kids4.jpg';
import kids5 from './kids5.jpg';
import kids6 from './kids6.jpg';
import pierreself from './pierreself.jpg';
import pierreselfie from './pierreselfie.jpg';
import pierreselfie2 from './pierreselfie2.jpg';
import trophypic from './trophypic.jpg';
import trophypic2 from './trophypic2.jpg';


const images = [
    {
        photo: pierreselfie,
    },
    {
        photo: boyngirlsclubpic,
    },
    {
        photo: boyngirlsclubpic3,
    },
    {
        photo: boyngirlsclubpic4,
    },
    {
        photo: boysngirlsclub2,
    },
    {
        photo: karate1,
    },
    {
        photo: karate2,
    },
    {
        photo: karate3,
    },
    {
        photo: karate4,
    },
    {
        photo: kids,
    },
    {
        photo: kids2,
    },
    {
        photo: kids3,
    },
    {
        photo: kids4,
    },
    {
        photo: kids5,
    },
    {
        photo: kids6,
    },
    {
        photo: pierreself,
    },
    
    {
        photo: pierreselfie2,
    },
    {
        photo: trophypic,
    },
    {
        photo: trophypic2,
    },
];

export default images;

